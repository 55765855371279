<ng-container *transloco="let t">

    <div class="container h-100 ">
        <div class="row">
            <div class="w-50 mx-auto align-items-center" align="center">
                <select class="form-control form-control-sm" style="width: 200px;"
                        [(ngModel)]="lang"
                        name="lang"
                        (change)="checkLang()" >
                    <option value="en" selected>{{ t('english') }}</option>
                    <option value="es">{{ t('spanish') }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm justify-content">

            </div>
            <div class="col-sm justify-content form-content">

                <form class="form-signin">
                    <h1 class="h3 mb-3 font-weight-normal">{{ t('home_title') }}</h1>

                    <div class="input-group">
                        <label for="code" class="sr-only">Coupon code</label>
                        <input
                                id="code"
                                type="text"
                                [(ngModel)]="code"
                                name="code"
                                class="form-control is-focus text-uppercase"
                                [className]="isUsed ? 'is-invalid' : 'is-focus'"
                                placeholder="{{ t('home_code_placeholder') }}"
                                ng-model="couponCode"
                                required autofocus>
                        <div class="invalid-feedback" *ngIf="isUsed">
                            {{ t('home_code_placeholder') }}
                        </div>
                    </div>


                    <button class="btn btn-md btn-primary btn-fy" type="button" (click)="checkCoupon()">{{ t('button') }}</button>
                </form>

            </div>
            <div class="col-sm justify-content">

            </div>
        </div>
    </div>
</ng-container>
