import { Injectable, Component } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {TranslocoService} from "@ngneat/transloco";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'get';
  lang = '';

  constructor(private translocoService: TranslocoService,) {
    this.translocoService = translocoService;
    this.lang = this.translocoService.getActiveLang();
    // alert(this.lang);
    window.location.replace("https://storybook-app.com/"+ this.lang +"/redeemcoupon/");
  }

}
