import {Component, OnInit} from '@angular/core';
import { environment } from "../../environments/environment"

import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";

import {NotifierService} from "angular-notifier";
import {TranslocoService} from "@ngneat/transloco";

@Component({
    selector: 'app-check-coupon',
    templateUrl: './check-coupon.component.html',
    styleUrls: ['./check-coupon.component.scss']
})
export class CheckCouponComponent implements OnInit {

    private readonly notifier: NotifierService;
    URL_PROD = environment.apiUrl;

    constructor(private httpClient: HttpClient, private router: Router, notifierService: NotifierService, private translocoService: TranslocoService,) {
        this.notifier = notifierService;
        this.translocoService = translocoService;
    }

    code = '';
    lang = '';
    isUsed;

    ngOnInit(): void {
        localStorage.setItem('current_coupon', '');
        localStorage.setItem('profile_email', '');
        this.lang = this.translocoService.getActiveLang();
    }

    checkLang(){
        this.translocoService.setActiveLang(this.lang);
    }

    checkCoupon() {

        let couponCode = this.code;
        couponCode = couponCode.toUpperCase();
        if (couponCode == '') {
            couponCode = '123'
            this.isUsed = true;
        }
        this.httpClient.post<any>(this.URL_PROD + 'v3/getCouponByCode/',
            {"code": couponCode}).subscribe(data => {
                if ('error' in data && data.error == false) { //  && data.coupon_package_type == 'random'
                    this.isUsed = data.used;
                    localStorage.setItem('current_coupon', couponCode);
                    this.router.navigate(['check-email'], {queryParams: {coupon: couponCode}});
                // } else if ('error' in data && data.error == false && data.coupon_package_type == 'static') {
                //     this.isUsed = data.used;
                //     localStorage.setItem('current_coupon', couponCode);
                //     this.router.navigate(['check-invoice'], {queryParams: {coupon: couponCode}});
                } else if ('error' in data && data.error == 'doesNotExist') {
                    this.isUsed = true;
                    this.notifier.notify("error", "Sorry, the coupon does not exist!");
                } else if ('error' in data && data.error == 'couponUsed') {
                    this.isUsed = true;
                    this.notifier.notify("error", "Sorry, the coupon is already used!");
                } else if ('error' in data && data.error == 'couponExpired') {
                    this.isUsed = true;
                    this.notifier.notify("error", "Sorry, the coupon is expired!");
                } else {
                    this.isUsed = true;
                    this.notifier.notify("error", "Sorry, the coupon does not exist!");
                }
            },
            error => {
                if (error.status != 200) {
                    this.notifier.notify("error", "Sorry, the coupon is already used!");
                }
            }
        )
    }

}
