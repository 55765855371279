<ng-container *transloco="let t">
<div class="container-fluid">
    <div class="row">
      <div class="col-sm justify-content">

      </div>
      <div class="col-sm justify-content form-content">

<!--        <img src="assets/send.png" alt="" height="30px" /><br /><br />-->
        <h1 class="h3 mb-3 font-weight-normal">{{ t('download_the_app') }}</h1>
        <p>{{ t('welcome_msg') }}</p>

        <a href="https://play.google.com/store/apps/details?id=app.storybook.story" target="_blank">
            <img src="assets/playstore@2x.png" alt="" height="40px" />
        </a>
        <a href="https://apps.apple.com/us/app/bedtime-baby-sleep-app/id1355190474?l=es&ls=1" target="_blank">
            <img src="assets/appstore@2x.png" alt="" height="40px" />
        </a>

          <a href="https://storybook-app.com/auth/recover-password/">
            <p class="mt-4">{{ t('forgot_password') }}</p>
          </a>

      </div>
      <div class="col-sm justify-content">

      </div>
    </div>
  </div>
</ng-container>
