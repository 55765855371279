<ng-container *transloco="let t">


    <div class="container h-100 ">
        <div class="row">
            <div class="w-50 mx-auto align-items-center" align="center">
                <select class="form-control form-control-sm" style="width: 200px;"
                        [(ngModel)]="lang_site"
                        name="lang"
                        (change)="checkLang()" >
                    <option value="en" selected>{{ t('english') }}</option>
                    <option value="es">{{ t('spanish') }}</option>
                </select>
            </div>
        </div>
    </div>


    <div class="container-fluid">
        <div class="row">
            <div class="col-sm justify-content">

            </div>
            <div class="col-sm justify-content form-content">

                <form [formGroup]="createForm"> <!----  ng-model="createParent" -->

                    <div class="col">
                        <label for="coupon">{{ t('coupon') }}</label>
                        <input type="text"
                               class="form-control is-focus"
                               [className]="isUsed ? 'is-invalid' : 'is-valid'"
                               id="coupon" placeholder="Coupon"
                               [value]="coupon"
                               required disabled>
                    </div>

                    <div class="col mt-4"> <!-- [(ngModel)]="name" -->
                        <input type="text" class="form-control is-focus" id="name" placeholder="{{ t('name') }}"
                               name="name"
                               [(ngModel)]="name"
                               formControlName="name"
                               required>
                        <div style="color:#f44336;"
                             *ngIf="createForm.get('name').errors && createForm.get('name').touched">
                            {{ t('name_error') }}
                        </div>
                    </div>

                    <div class="col mt-4"> <!-- [(ngModel)]="email"  -->
                        <input type="email" class="form-control is-focus" id="email" placeholder="{{ t('email') }}"
                               name="email"
                               [(ngModel)]="email"
                               formControlName="email"
                               disabled
                               required>
                        <div style="color:#f44336;"
                             *ngIf="createForm.get('email').errors && createForm.get('email').touched">
                            {{ t('email_error') }}
                        </div>
                        <div style="color:#f44336;" *ngIf="existing_mail == true">{{ t('existing_mail') }}</div>
                    </div>

                    <div class="col mt-4">
                        <div class="form-group"> <!-- [(ngModel)]="lang" -->
                            <select class="form-control is-focus" id="language"
                                    [(ngModel)]="lang"
                                    formControlName="lang"
                                    name="lang"
                            >
                                <option value="en" selected>{{ t('english') }}</option>
                                <option value="es">{{ t('spanish') }}</option>
                                <option value="pt">{{ t('portuguese') }}</option>
                            </select>
                        </div>
                        <div style="color:#f44336;"
                             *ngIf="createForm.get('lang').errors && createForm.get('lang').touched">
                            {{ t('lang_error') }}
                        </div>
                    </div>

                    <div class="col mt-4"> <!-- [(ngModel)]="password"  -->
                        <input type="password" class="form-control is-focus" id="password" placeholder="{{ t('password') }}"
                               name="password"
                               [(ngModel)]="password"
                               formControlName="password"
                               required>
                        <div style="color:#f44336;"
                             *ngIf="createForm.get('password').errors && createForm.get('password').touched">
                            {{ t('password_error') }}
                        </div>
                    </div>


                    <button class="btn btn-md btn-primary btn-fy" type="button"
                            (click)="createParent()"
                            [disabled]="createForm.invalid">{{ t('button') }}</button>
                </form>

            </div>
            <div class="col-sm justify-content">

            </div>
        </div>
    </div>

</ng-container>
