<div class="container-fluid">
    <div class="row not-found">
      <div class="col-sm justify-content">

      </div>
      <div class="col-sm justify-content form-content">
            <p>404 - NOT FOUND</p>
      </div>
      <div class="col-sm justify-content">

      </div>
    </div>
</div>  