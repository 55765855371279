import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { environment } from "../../environments/environment"

import {NotifierService} from "angular-notifier";
import {Router} from "@angular/router";

@Component({
    selector: 'app-check-email',
    templateUrl: './check-email.component.html',
    styleUrls: ['./check-email.component.scss']
})
export class CheckEmailComponent implements OnInit {

    private readonly notifier: NotifierService;
    URL_PROD = environment.apiUrl;

    constructor(private httpClient: HttpClient, private router: Router, notifierService: NotifierService) {
        this.notifier = notifierService;
    }

    email = '';
    couponCode = ''
    isEmail = null;
    checking = false;
    isRedeemed = false;

    ngOnInit(): void {
        this.couponCode = localStorage.getItem('current_coupon');
        if (this.couponCode == '') {
            this.router.navigate(['']);
        }
    }

    checkEmail() {
        this.checking = true;
        let payload = {
            'email': this.email
        }
        this.email = this.email.toLowerCase();
        this.isEmail = this.validateEmail(this.email);
        if (!this.isEmail) {
            this.notifier.notify("error", "Sorry, the email is incorrect!");
            this.isEmail = false;
            this.checking = false;
        }
        if (this.isEmail == true) {
            this.httpClient.post<any>(this.URL_PROD + 'v4/existProfile/',
                payload).subscribe(data => {
                    let profile_id = data.id;
                    this.redeemCoupon(this.couponCode, profile_id);
                }, error => {
                    if (error.status != 200) {
                        localStorage.setItem('profile_email', this.email);
                        this.router.navigate(['create'], {queryParams: {coupon: this.couponCode}});
                        this.checking = false;
                    }
                }
            )
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    redeemCoupon(coupon_code, profile_id) {
        let device = {
            "device_id": "",
            "token_firebase": "",
            "os": "",
            "os_version": "",
            "model": "",
            "brand": ""
        }
        let paramsCoupon = {
            "code": coupon_code,
            "parent_id": profile_id,
            "device": device
        }
        this.httpClient.post<any>(this.URL_PROD + 'v3/redeemCoupon/',
            paramsCoupon).subscribe(data => {
            this.isRedeemed = true;
            this.router.navigate(['thanks']);
        }, error => {
            this.isRedeemed = false;
            this.notifier.notify("error", "Sorry, we had some error!");
            this.checking = false;
        })
    }

}
