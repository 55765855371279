<ng-container *transloco="let t">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm justify-content">

            </div>
            <div class="col-sm justify-content form-content">

                <form class="form-signin">
                    <h1 class="h3 mb-3 font-weight-normal">{{ t('check_email_title') }}</h1>

                    <div class="input-group">
                        <label for="email-input" class="sr-only">Email</label>
                        <input
                                type="email"
                                style="text-transform: lowercase;"
                                id="email-input"
                                email="true"
                                [(ngModel)]="email"
                                name="email"
                                class="form-control is-focus"
                                [className]="isEmail ? 'is-invalid' : 'is-focus'"
                                placeholder="{{ t('check_email_placeholder') }}"
                                ng-model="email"
                                required autofocus>
                        <div class="invalid-feedback" *isEmail="isEmail">
                            {{ t('check_email_placeholder') }}
                        </div>
                    </div>


                    <button class="btn btn-md btn-primary btn-fy" type="button"
                            (click)="checkEmail()">
                        <div class="spinner-border spinner-border-sm" role="status" *ngIf="checking == true">
                            <span class="sr-only">Loading...</span>
                        </div>
                        {{ t('check_email_btn') }}
                    </button>
                </form>

            </div>
            <div class="col-sm justify-content">

            </div>
        </div>
    </div>
</ng-container>