import {Component, OnInit} from '@angular/core';
import { environment } from "../../environments/environment"

import {TranslocoService} from '@ngneat/transloco';
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-thanks',
    templateUrl: './thanks.component.html',
    styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {

    URL_PROD = environment.apiUrl;
    lang_site = '';
    profileID = '';
    url_android = 'https://play.google.com/store/apps/details?id=app.storybook.story';
    url_ios = 'https://apps.apple.com/us/app/bedtime-baby-sleep-app/id1355190474?l=es&ls=1';

    constructor(private translocoService: TranslocoService, private httpClient: HttpClient,) {
        this.translocoService = translocoService;
        this.profileID = localStorage.getItem('profile_id');
    }

    ngOnInit(): void {
        this.lang_site = this.translocoService.getActiveLang();
        const payload = {
            'profile_id': this.profileID
        }
        if (this.profileID != '') {
            this.httpClient.post<any>(this.URL_PROD + 'v4/settings/generateLinkToken/',
                payload).subscribe(data => {
                this.url_android = data.link;
                this.url_ios = data.link;
            });
        }
    }
}

