import {Router, ActivatedRoute, Params} from '@angular/router';
import {Component, OnInit, Input} from '@angular/core';

import { environment } from "../../environments/environment"

import {FormControl, FormGroup, Validators} from '@angular/forms';

import {HttpClient, HttpErrorResponse} from "@angular/common/http";

import {NotifierService} from "angular-notifier";
import {TranslocoService} from '@ngneat/transloco';


@Component({
    selector: 'app-create-parent',
    templateUrl: './create-parent.component.html',
    styleUrls: ['./create-parent.component.scss']
})
export class CreateParentComponent implements OnInit {

    URL_PROD = environment.apiUrl;
    private readonly notifier: NotifierService;
    //const ctrl = new FormControl('some value');
    //console.log(ctrl.value);
    createForm: FormGroup;

    isUsed;

    coupon = '';
    name = '';
    email = '';
    lang_site = '';
    lang = 'en';
    password = '';
    existing_mail = false;
    profileID = '';

    constructor(
        private httpClient: HttpClient,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translocoService: TranslocoService,
        notifierService: NotifierService
    ) {
        this.notifier = notifierService;
        this.translocoService = translocoService;
    }

    ngOnInit(): void {
        this.lang_site = this.translocoService.getActiveLang();
        this.createForm = new FormGroup({
            name: new FormControl('', [Validators.required, Validators.minLength(2)]),
            email: new FormControl('', [Validators.required, Validators.email]),
            lang: new FormControl('', [Validators.required, Validators.minLength(2)]),
            password: new FormControl('', [Validators.required, Validators.minLength(5)])
        });

        this.activatedRoute.queryParams.subscribe(params => {
            this.coupon = params['coupon'];

            this.httpClient.post<any>(this.URL_PROD + 'v3/getCouponByCode/',
                {"code": this.coupon}).subscribe(data => {
                if ('error' in data && data.error == false) {
                    this.isUsed = data.used;
                } else if ('error' in data && data.error == 'doesNotExist') {
                    this.isUsed = true;
                    this.notifier.notify("error", "Sorry, the coupon does not exist!");
                } else if ('error' in data && data.error == 'couponUsed') {
                    this.isUsed = true;
                    this.notifier.notify("error", "Sorry, the coupon is already used!");
                } else if ('error' in data && data.error == 'couponExpired') {
                    this.isUsed = true;
                    this.notifier.notify("error", "Sorry, the coupon is expired!");
                } else {
                    this.isUsed = true;
                    this.notifier.notify("error", "Sorry, the coupon does not exist!");
                }
            })


        });

        this.email = localStorage.getItem('profile_email');
        if(this.email==''){
            this.router.navigate(['']);
        }

    }

    checkLang() {
        this.translocoService.setActiveLang(this.lang_site);
    }

    createParent() {
        let device = {
            "device_id": "",
            "token_firebase": "",
            "os": "",
            "os_version": "",
            "model": "",
            "brand": ""
        }
        let params = {
            "name": this.name,
            "email": this.email,
            "lang": this.lang,
            "password": this.password,
            "register_type": "email",
            "device": device
        }
        this.httpClient.post<any>(this.URL_PROD + 'v4/signup/email/',
            params).subscribe(data => {
                let parentCreated = {
                    "parent_id": data.profile.parent_id,
                    "parent_first_name": data.profile.parent_first_name,
                    "language": data.profile.language,
                    "email": data.profile.email,
                    "reset_password": data.profile.reset_password,
                    "active": data.profile.active
                }
                this.profileID = data.parent_id;
                // if(parentCreated.active == true){
                let paramsCoupon = {
                    "code": this.coupon,
                    "parent_id": parentCreated.parent_id,
                    "device": device
                }
                this.httpClient.post<any>(this.URL_PROD + 'v3/redeemCoupon/',
                    paramsCoupon).subscribe(data => {
                    this.router.navigate(['thanks']);
                })
                // }
                // if (data['auth_already_exist'] == true) {
                //     console.log(data);
                //     let paramsCoupon = {
                //         "code": this.coupon,
                //         "parent_id": data['auth'],
                //         "device": device
                //     }
                //     this.profileID = data['auth'];
                //     this.httpClient.post<any>(this.URL_PROD + 'v3/redeemCoupon/',
                //         paramsCoupon).subscribe(data => {
                //         this.router.navigate(['thanks']);
                //     })
                // }
                localStorage.setItem('profile_id', this.profileID);
            },
            error => {
                if (error.status != 200) {
                    this.existing_mail = true;
                    this.notifier.notify("error", "Sorry, Problems redeeming the coupon");
                }
            }
        )
    }

}


export interface createParent {
    name: string;
    email: string;
    lang: string,
    password: string
}




