import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {NotifierService} from "angular-notifier";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-check-invoice',
  templateUrl: './check-invoice.component.html',
  styleUrls: ['./check-invoice.component.scss']
})
export class CheckInvoiceComponent implements OnInit {

  private readonly notifier: NotifierService;

  constructor(
      private httpClient: HttpClient,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private translocoService: TranslocoService,
      notifierService: NotifierService) {
    this.notifier = notifierService;
    this.translocoService = translocoService;
  }

  couponCode = '';
  lang = '';
  invoice = '';
  isInvalid = false;

  ngOnInit(): void {
    this.lang = this.translocoService.getActiveLang();
    this.activatedRoute.queryParams.subscribe(params => {
      this.couponCode = params['coupon'];
    })
  }

  checkLang(){
    this.translocoService.setActiveLang(this.lang);
  }

  checkInvoice() {
    let invoiceNumberInt = parseInt(this.invoice);
    let invoiceNumberStr = invoiceNumberInt.toString();

    // console.log('Leng: ', invoiceNumberStr.length, invoiceNumberStr.length != 15);
    // console.log('NaN: ', invoiceNumberInt, isNaN(invoiceNumberInt), isNaN(invoiceNumberInt) == true);
    // console.log('result:  ', invoiceNumberStr.length != 15 && isNaN(invoiceNumberInt) == true);

    if (invoiceNumberStr.length != 15 || isNaN(invoiceNumberInt) == true) {
      this.isInvalid = true;
      this.notifier.notify("error", "¡Número de factura incorrecto!");
    } else{
      this.router.navigate(['create'], {queryParams: {coupon: this.couponCode}});
    }

  }

}
