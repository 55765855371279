 import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { NotifierModule } from "angular-notifier";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateParentComponent } from './create-parent/create-parent.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CheckCouponComponent } from './check-coupon/check-coupon.component';
import { ThanksComponent } from './thanks/thanks.component';
import { CheckInvoiceComponent } from './check-invoice/check-invoice.component';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { CheckEmailComponent } from './check-email/check-email.component';



@NgModule({
  declarations: [
    AppComponent,
    CreateParentComponent,
    NotFoundComponent,
    CheckCouponComponent,
    ThanksComponent,
    CheckInvoiceComponent,
    CheckEmailComponent
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    NotifierModule,
    TranslocoRootModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
