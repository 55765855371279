import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AppComponent } from './app.component';
import { CheckCouponComponent } from './check-coupon/check-coupon.component';
import { CheckInvoiceComponent } from './check-invoice/check-invoice.component';
import { CreateParentComponent } from './create-parent/create-parent.component';
import { ThanksComponent } from './thanks/thanks.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CheckEmailComponent } from './check-email/check-email.component';


const routes: Routes = [
  {
    path: '',
    component: CheckCouponComponent
  },
  {
    path: 'check-email',
    component: CheckEmailComponent
  },
  // {
  //   path: 'check-invoice',
  //   component: CheckInvoiceComponent
  // },
  {
    path: 'create',
    component: CreateParentComponent
  },
  {
    path: 'thanks',
    component: ThanksComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
